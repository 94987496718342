import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import React from "react";

function AtendimentoAlert({ atendimento }) {
  const codigoDN = atendimento.produtoRegional.produtoNacional.codigoDN;

  let content;
  if (codigoDN === "412157") {
    content = (
      <>
        <Alert severity="warning">
          <AlertTitle>
            <strong>Atenção:</strong> Atendimento Piloto - Brasil Produtivo Lean Manufacturing
          </AlertTitle>
          Este é um atendimento piloto. Para garantir a conclusão deste atendimento, observe as seguintes diretrizes obrigatórias:
          <ul>
            <li>
              <strong>Documentação Necessária:</strong> A apresentação dos documentos listados abaixo é imprescindível:
              <ul>
                <li>Termo de Adesão ou Contrato</li>
                <li>Relatório de Atendimento</li>
                <li>Formulário de Priorização Padrão</li>
              </ul>
            </li>
            <li>
              <strong>Receita:</strong> O atendimento deve conter receita.
            </li>
            <li>
              <strong>Produção:</strong> O atendimento deve conter produção.
            </li>
            <li>
              <strong>Indicadores de Desempenho:</strong> Os indicadores listados são necessários:
              <ul>
                <li>Produtividade</li>
                <li>Retorno do Programa</li>
              </ul>
            </li>
            <li>
              Para conclusão do atendimento necessário que o percentual do Indicador de Produtividade esteja entre <strong>20%-100% </strong>, caso contrário deve ser enviado para parecer DN, com a
              justificativa do resultado no campo <strong>COMENTÁRIO</strong>.
            </li>
          </ul>
        </Alert>
      </>
    );
  } else if (codigoDN === "412158") {
    content = (
      <Alert severity="warning">
        <AlertTitle>
          <strong>Atenção:</strong> Atendimento Piloto - Brasil Produtivo Eficiência Energética
        </AlertTitle>
        Este é um atendimento piloto. Para garantir a conclusão deste atendimento, observe as seguintes diretrizes obrigatórias:
        <ul>
          <li>
            <strong>Documentação Necessária:</strong> A apresentação dos documentos listados abaixo é imprescindível:
            <ul>
              <li>Termo de Adesão ou Contrato</li>
              <li>Relatório de Atendimento</li>
              <li>Formulário de Priorização Padrão</li>
            </ul>
          </li>
          <li>
            <strong>Receita:</strong> O atendimento deve conter receita.
          </li>
          <li>
            <strong>Produção:</strong> O atendimento deve conter produção.
          </li>
          <li>
            <strong>Indicadores de Desempenho:</strong> Os indicadores listados são necessários:
            <ul>
              <li>Retorno do Programa (EE) </li>
              <li>Redução de Consumo Energético (%)</li>
              <li>Redução de Consumo Energético (Mhw/Ano)</li>
              <li>Redução de Custo com Energia (R$/Ano)</li>
              <li>Emissão de CO2 evitada (CO2/ano) </li>
              <li>Usina Equivalente (MW)</li>
              <li>Casa Equivalente (n. de casas)</li>
            </ul>
          </li>
          <li>
            Para conclusão do atendimento necessário que o percentual do Redução de Consumo Energético esteja entre <strong>10%-100% </strong>, caso contrário deve ser enviado para parecer DN, com a
            justificativa do resultado no campo <strong>COMENTÁRIO</strong>.
          </li>
        </ul>
      </Alert>
    );
  } else if (codigoDN === "412151") {
    content = (
      <Alert severity="warning">
        <AlertTitle>
          <strong>Atenção:</strong> Atendimento Melhoria de Produtividade - Atendimento Regional
        </AlertTitle>
        Para garantir a conclusão deste atendimento, observe as seguintes diretrizes obrigatórias:
        <ul>
          <li>
            <strong>Documentação Necessária:</strong> A apresentação dos documentos listados abaixo é imprescindível:
            <ul>
              <li>Relatório de atendimento final (deve conter informações dos indicadores e assinado pelas partes)</li>
              <li>Termo de encerramento (assinado pelo cliente)</li>
            </ul>
          </li>
          <li>
            <strong>Receita:</strong> apropriação de receita.
          </li>
          <li>
            <strong>Produção:</strong> apropriação de produção.
          </li>
          <li>
            <strong>Indicadores de Desempenho:</strong> Os indicadores listados são necessários:
            <ul>
              <li>Produtividade</li>
            </ul>
          </li>
          <li>
            Para conclusão do atendimento necessário que o percentual do Indicador de Produtividade esteja entre <strong>20%-100% </strong>, caso contrário deve ser enviado para parecer DN, com a
            justificativa do resultado no campo <strong>COMENTÁRIO.</strong>
          </li>
        </ul>
      </Alert>
    );
  } else if (codigoDN === "412148") {
    content = (
      <Alert severity="warning">
        <AlertTitle>
          <strong>Atenção:</strong> Atendimento Rota 2030 Hands On
        </AlertTitle>
        Para garantir a conclusão deste atendimento, observe as seguintes diretrizes obrigatórias:
        <ul>
          <li>
            <strong>Documentação Necessária:</strong> A apresentação dos documentos listados abaixo é imprescindível:
            <ul>
              <li>Cartão CNPJ (upload da Plataforma Inovação)</li>
              <li>Proposta Técnica (upload da Plataforma Inovação)</li>
              <li>Termo de Adesão ou Contrato</li>
              <li>Termo de encerramento</li>
              <li>Relatório de Atendimento (excel)</li>
              <li>Relatório de Atendimento (pdf)</li>
            </ul>
            <strong>Documentação Opcional:</strong>
            <ul>
              <li>MFV Estado Presente e Futuro</li>
              <li>Matriz de Risco e Relatórios A3 do Risco</li>
            </ul>
          </li>
          <li>
            <strong>Receita:</strong> O atendimento deve conter receita apropriada.
          </li>
          <li>
            <strong>Produção:</strong> O atendimento deve conter produção apropriada
          </li>
          <li>
            <strong>Indicadores de Desempenho Obrigatórios:</strong> Os indicadores listados são necessários:
            <ul>
              <li>Retorno do Programa</li>
              <li>Produtividade Lean (automático)</li>
              <li>Redução de Movimentação Lean</li>
              <li>OEE</li>
              <li>Disponibilidade OEE</li>
              <li>Performance OEE</li>
              <li>Qualidade Lean</li>
              <li>Qualidade OEE</li>
              <li>Produtividade Digitalização</li>
              <li>Retorno do Programa Digitalização</li>
              <li>Análise de risco (necessário para consultora de Gestão de Risco)</li>
              <li>Número de operadores por turno (necessário para Indicador de Produtividade Lean)</li>
              <li>Quantidade de horas por turno (necessário para Indicador de Produtividade Lean)</li>
              <li>Quantidade produzida nas horas trabalhadas por turno (necessário para Indicador de Produtividade Lean)</li>
            </ul>
          </li>
          <li>Todos os atendimentos do Rota 2030 Hands On necessitam de PARECER DN CONFORME para conclusão.</li>
        </ul>
      </Alert>
    );
  } else if (codigoDN === "42120") {
    content = (
      <Alert severity="warning">
        <AlertTitle>
          <strong>Atenção:</strong> Atendimento do Programa Brasil+Produtivo
        </AlertTitle>
        <ul>
          <li>
            <strong>Produto B+P SMART FACTORY</strong>
            <ul>
              Indicadores de Desempenho Desejáveis:
              <li>Produtividade Lean</li>
              <li>Redução de Movimentação Lean</li>
              <li>OEE</li>
              <li>Disponibilidade OEE</li>
              <li>Performance OEE</li>
              <li>Qualidade OEE</li>
            </ul>
            <strong>Documentação obrigatória:</strong>
            <ul>
              <li>Relatório de atendimento crítico de encerramento, com as comprovações conforme regulamento (escaneado/pdf assinado); </li>
              <li>Relação das empresas validadoras, conforme arquivo padronizado (excel);</li>
            </ul>
          </li>
        </ul>
      </Alert>
    );
  } else if (codigoDN === "412159") {
    content = (
      <Alert severity="warning">
        <AlertTitle>
          <strong>Atenção:</strong> Atendimento do Programa Brasil+Produtivo
        </AlertTitle>
        <ul>
          <li>
            <strong>Produto B+P Manufatura Enxuta</strong>
            <ul>
              Indicadores de Desempenho obrigatório:
              <li>Retorno do programa (payback)</li>
              <li>Indicador Produtividade (%) (mínimo 20%)</li>
              <li>Ganho Financeiro Mensal (R$)</li>
              <li>Empresa apta a Categoria Smart Factory?</li>
            </ul>
            <strong>Documentação obrigatória:</strong>
            <ul>
              <li>Cartão CNPJ</li>
              <li>Proposta Comercial (assinado SENAI e empresa)</li>
              <li>Relatório final de atendimento com menção de indicadores (excel)</li>
              <li>Relatório final de atendimento com menção de indicadores (assinado SENAI e empresa)</li>
              <li>Formulário Smart Factory (excel)</li>
              <li>Formulário Smart Factory (assinado SENAI e empresa)</li>
              <li>Formulário de priorização (excel)</li>
              <li>Formulário de priorização (assinado SENAI e empresa)</li>
              <li>Relatório de Assessoria Educacional (4h)</li>
            </ul>
            <div>Observação: Para conclusão do atendimento necessário que o percentual do <strong>Indicador de Produtividade</strong> esteja <strong>entre 20%-100%</strong> , caso contrário deve ser enviado para
            parecer DN, com a justificativa do resultado no campo <strong>COMENTÁRIO</strong></div>
          </li>
        </ul>
      </Alert>
    );
  } else if (codigoDN === "412160") {
    content = (
      <Alert severity="warning">
        <AlertTitle>
          <strong>Atenção:</strong> Atendimento do Programa Brasil+Produtivo
        </AlertTitle>
        <ul>
          <li>
            <strong>Produto B+P Eficiência Energética</strong>
            <ul>
              Indicadores de Desempenho obrigatório:
              <li>Redução de consumo energético (%) (mínimo 10%)</li>
              <li>Redução de Consumo Energético (MWh/ano)</li>
              <li>Redução de Custo com Energia (R$/ano)</li>
              <li>Emissão de CO2 evitado (ton CO2/ano)</li>
              <li>Usina equivalente (MW)</li>
              <li>Casa equivalente (n. de casas)</li>
              <li>Retorno do programa (EE)</li>
              <li>Empresa apta a Categoria Smart Factory?</li>
            </ul>
            <strong>Documentação obrigatória:</strong>
            <ul>
              <li>Cartão CNPJ</li>
              <li>Proposta Comercial (assinado SENAI e empresa)</li>
              <li>Relatório final de atendimento com menção de indicadores (excel)</li>
              <li>Relatório final de atendimento com menção de indicadores (assinado SENAI e empresa)</li>
              <li>Formulário Smart Factory (excel)</li>
              <li>Formulário Smart Factory (assinado SENAI e empresa)</li>
              <li>Formulário de priorização (excel)</li>
              <li>Formulário de priorização (assinado SENAI e empresa)</li>
              <li>Relatório de Assessoria Educacional (4h)</li>
            </ul>
            <div>Observação: Para conclusão do atendimento necessário que o percentual do <strong>Indicador de Redução de Consumo Energético (%)</strong> esteja entre <strong>10%-100% </strong>, caso contrário deve
            ser enviado para parecer DN, com a justificativa do resultado no campo <strong>COMENTÁRIO</strong>.</div>
          </li>
        </ul>
      </Alert>
    );
  } else if (codigoDN === "412161") {
    content = (
      <Alert severity="warning">
        <AlertTitle>
          <strong>Atenção:</strong> Atendimento do Programa Brasil+Produtivo
        </AlertTitle>
        <ul>
          <li>
            <strong>Produto NOVO B+P TRANSFORMAÇÃO DIGITAL (DIAGNÓSTICO) - Piloto</strong>
            <ul>
              Indicadores de Desempenho obrigatório:
              <li>Processos - Nível de Integração Vertical (0-5)</li>
              <li>Processos - Nível de Integração Horizontal (0-5)</li>
              <li>Processos - Nível de Integração do Ciclo de Vida do Produto (0-5)</li>
              <li>Tecnologia - Nível de Automação do Chão de Fábrica (0-5)</li>
              <li>Tecnologia - Nível de Automação da Empresa (0-5)</li>
              <li>Tecnologia - Nível de Automação das Instalações (0-5)</li>
              <li>Tecnologia - Nível de Conectividade do Chão de Fábrica (0-5)</li>
              <li>Tecnologia - Nível de Conectividade da Empresa (0-5)</li>
              <li>Tecnologia - Nível de Conectividade das Instalações (0-5)</li>
              <li>Tecnologia - Nível de Inteligência do Chão de Fábrica (0-5)</li>
              <li>Tecnologia - Nível de Inteligência da Empresa (0-5)</li>
              <li>Tecnologia - Nível de Inteligência das Instalações (0-5)</li>
              <li>Organização - Nível de Desenvolvimento e Capacitação da Mão de Obra (0-5)</li>
              <li>Organização - Nível de Liderança (0-5)</li>
              <li>Organização - Nível de Colaboração Intra e Entre Empresas (0-5)</li>
              <li>Organização - Nível de Estratégia e Governança (0-5)</li>
              <li>Dimensão Priorizada para Investimento</li>
              <li>Faixa de Investimento</li>
              <li>Inclui Hardware</li>
              <li>Inclui Software</li>
              <li>Inclui Serviço</li>
              <li>Tecnologia Principal</li>
              <li>Opção Da Empresa</li>
              <li>Oportunidade de PDI identificada</li>
              <li>CNPJ Da Fornecedora Principal</li>
            </ul>
            <strong>Documentação obrigatória:</strong>
            <ul>
              <li>Cartão CNPJ</li>
              <li>Proposta Comercial Fase Diagnóstico (assinado SENAI e empresa)</li>
              <li>Relatório de Atendimento da Fase Diagnóstico (excel)</li>
              <li>Relatório de Atendimento da Fase Diagnóstico (assinado SENAI e empresa)</li>
            </ul>
            <strong>Apropriar receita</strong>
            <br />
            <strong>Apropriar produção</strong>
          </li>
        </ul>
      </Alert>
    );
  } else if (codigoDN === "412163") {
    content = (
      <Alert severity="warning">
        <AlertTitle>
          <strong>Atenção:</strong> Atendimento do Programa Brasil+Produtivo
        </AlertTitle>
        <ul>
          <li>
            <strong>Produto NOVO B+P TRANSFORMAÇÃO DIGITAL (INVESTIMENTO) - Piloto</strong>
            <ul>
              Indicadores de Desempenho obrigatório:
              <li>Produtividade Lean</li>
              <li>Retorno Do Programa</li>
              <li>OEE</li>
              <li>Disponibilidade OEE</li>
              <li>Performance OEE</li>
              <li>Qualidade OEE</li>
              <li>CNPJ Da Fornecedora Principal</li>
            </ul>
            <strong>Documentação obrigatória:</strong>
            <ul>
              <li>Cartão CNPJ</li>
              <li>Proposta Comercial Fase Investimento (assinado SENAI e empresa)</li>
              <li>Relatório de Atendimento da Fase Investimento (excel)</li>
              <li>Relatório de Atendimento da Fase Investimento (assinado SENAI e empresa)</li>
            </ul>
            <strong>Apropriar receita</strong>
            <br />
            <strong>Apropriar produção</strong>
          </li>
        </ul>
      </Alert>
    );
  } else if (codigoDN === "42128") {
    content = (
      <Alert severity="warning">
        <AlertTitle>
          <strong>Atenção:</strong> Atendimento do Programa Brasil+Produtivo
        </AlertTitle>
        <ul>
          <li>
            <strong>Produto NOVO B+P SMART FACTORY</strong>
            <ul>
              Indicadores de Desempenho obrigatório:
              <li>Ganho Médio de Produtividade</li>
              <li>Ganho Médio de OEE</li>
              <li>Número de Empresas Validadoras aprovadas para o projeto</li>
              <li>CNPJs das Empresas Validadoras em que a solução foi validada</li>
            </ul>
            <strong>Documentação obrigatória:</strong>
            <ul>
              <li>Cartão CNPJ da empresa parceira, data de emissão 30 dias da contratação no máximo</li>
              <li>Relatório de Encerramento, assinado Gestor da Carteira de projetos no DR e representante da empresa</li>
              <li>Planilha de Cálculo dos Indicadores Smart Factory (formato .xlsx)</li>
            </ul>
            <strong>Apropriar 100% da receita</strong>
            <br />
            <strong>Apropriar 100% da produção</strong>
          </li>
          <strong>Observação: O atendimento no SGT deverá ser concluído após aprovação do projeto no SGF</strong>
          <div>Para conclusão do atendimento necessário que a quantidade de CNPJ distintos do <strong>Indicador 4</strong> esteja igual ao número de <strong>Indicador 3 </strong>, caso contrário deve ser enviado para parecer DN, com a justificativa do resultado no campo <strong>COMENTÁRIO</strong>.</div>
        </ul>
      </Alert>
    );
  } else {
    // Alerta padrão para outros códigos
    // content = (
    //   <Alert severity="info">
    //     <AlertTitle><strong>Atenção:</strong> Atendimento Piloto - Brasil Produtivo Lean Manufacturing</AlertTitle>
    //     // Conteúdo do alerta padrão...
    //   </Alert>
    // );
  }

  return (
    <Grid item xs={12}>
      {content}
    </Grid>
  );
}

export default AtendimentoAlert;
